@font-face {
font-family: '__sohneLeicht_2a3dbe';
src: url(/_next/static/media/7682b280ce4095ac-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sohneLeicht_Fallback_2a3dbe';src: local("Arial");ascent-override: 103.68%;descent-override: 28.89%;line-gap-override: 0.00%;size-adjust: 100.02%
}.__className_2a3dbe {font-family: '__sohneLeicht_2a3dbe', '__sohneLeicht_Fallback_2a3dbe', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}.__variable_2a3dbe {--sohne-leicht: '__sohneLeicht_2a3dbe', '__sohneLeicht_Fallback_2a3dbe', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}

@font-face {
font-family: '__sohneFett_a0d959';
src: url(/_next/static/media/7584226bda665cc7-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sohneFett_Fallback_a0d959';src: local("Arial");ascent-override: 97.17%;descent-override: 27.08%;line-gap-override: 0.00%;size-adjust: 106.72%
}.__className_a0d959 {font-family: '__sohneFett_a0d959', '__sohneFett_Fallback_a0d959', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}.__variable_a0d959 {--sohne-fett: '__sohneFett_a0d959', '__sohneFett_Fallback_a0d959', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}

